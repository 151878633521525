@import "../../variables.css";
.portfolioPage {
    width: auto;
    height: 100%;
    display: flex;
    overflow-y: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    box-sizing: border-box;
}

.banner {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    left: 100px;
}