.overlay {
    position: absolute;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

.hide {
    display: none;
}