.container {
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: overlay;
    display: flex;
    overflow-y: hidden;
}

.backgroundOverlay {
    flex-grow: 1;
    min-width: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    background-position: center;
    display: flex;
    flex-direction: column;
}