@import url(../../variables.css);
.aboutMeSlide {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 32px;
    max-width: 1200px;
    @media screen {
        flex-direction: row;
    }
    @media screen and (max-width: 1000px) {
        flex-direction: column;
        max-width: 600px;
        min-width: 20px;
        gap: 32px;
    }
}

.navigationBar {
    @media screen and (max-width: 700px) {
        display: none;
    }
}

.appIconSlide {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.iconRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    @media screen {
        gap: 32px;
    }
    @media screen and (max-width: 300px) {
        gap: 8px;
    }
}

.aboutMeColumn {
    display: flex;
    flex-direction: column;
    @media screen {
        gap: 16px;
    }
    @media screen and (max-width: 1000px) {
        gap: 8px;
    }
}

.aboutMeContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.germanyMap {
    object-fit: scale-down;
    object-position: top center;
    max-width: 100%;
    height: auto;
    display: block;
    @media screen {
        width: 100%;
        margin-top: 16px;
        margin-bottom: -32px;
    }
    @media screen and (max-width: 1000px) {
        height: 50%;
        margin-top: 16px;
        margin-bottom: -16px;
    }
}

.titleText {
    color: white;
    font-family: "Roboto";
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    @media screen {
        font-size: 21px;
    }
    @media screen and (min-width: 500px) {
        font-size: 24px;
    }
    @media screen and (min-width: 1300px) {
        font-size: 32px;
    }
}

.text {
    color: white;
    text-align: left;
    @media screen {
        font-size: 18px;
    }
    @media screen and (min-width: 500px) {
        font-size: 21px;
    }
    @media screen and (min-width: 1300px) {
        font-size: 28px;
    }
}

.primaryTextBackgroundColor {
    padding: 4px 8px;
    background-color: var(--primary-color);
    line-height: 48px;
}

.secondaryTextColor {
    color: var(--secondary-alt-color);
}

.contactSlide {
    width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
}