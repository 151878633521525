.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.scrollable {
    width: 100%;
    /*     scroll-snap-type: x mandatory; */
    display: flex;
    overflow-x: hidden;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;
    flex-grow: 1;
}

.scrollable:active {
    scroll-snap-type: none;
    user-select: none;
}

.indicator {
    margin-bottom: 32px;
}

.slide {
    scroll-snap-align: start;
    min-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}